import React from 'react'
import ReactGA from 'react-ga'
import Img from 'gatsby-image'

const CardNewsReusable = (props) => {

  const {alt_text, title, link, logo, articleDate} = props

  return (
    <div class="swiper-slide">
      <a href={link} target="_blank"
          onClick={() => {
            ReactGA.event({
                category: "Home Page",
                action: "Clicks",
                label: `${title}`
            })
          }} class="feature-box padding-4-half-rem-tb padding-4-rem-lr bg-white box-shadow-small box-shadow-extra-large-hover md-padding-3-rem-all sm-padding-5-rem-all border-top border-width-4px border-color-fast-blue br-10">
                            <div class="feature-box-icon margin-20px-bottom">
                                <img src={logo} alt={alt_text} />
                            </div>
                            <div class="feature-box-content last-paragraph-no-margin">
                                
                                <p>{title}</p>
								
								<span class="alt-font font-weight-500 text-extra-dark-gray margin-10px-top d-block text-extra-medium">{articleDate}</span>
                            </div>
                        </a>
      </div>
  )
}

export default CardNewsReusable
