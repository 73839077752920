import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'


const EmployeeTestimonialCard = (props) => {

    const {key, studentName, studentTestimonial, uniLogo, imgUrl, batch, place } = props

    const query = useStaticQuery(graphql`
        {
            leftQuote: allFile(filter: {relativePath: {eq: "left-quote.png"}}) {
                edges {
                    node {
                        id
                        base
                        childImageSharp {
                            fixed (width: 50, height: 50) {
                                base64
                                tracedSVG
                                aspectRatio
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
            }
            rightQuote: allFile(filter: {relativePath: {eq: "right-quote.png"}}) {
                edges {
                    node {
                        id
                        base
                        childImageSharp {
                            fixed (width: 50, height: 50) {
                                base64
                                tracedSVG
                                aspectRatio
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className="card shadow student-testimonial-card employee-testimonial-card">
            <div className="row">
                <div className="col-sm-4 mb-3 d-flex align-items-center justify-content-center">
                    <Img 
                        fixed={query.leftQuote.edges[0].node.childImageSharp.fixed}
                        className="left-quote-img"
                    />
                    <div className='student-testimonial-image-holder'>
                        <img src={imgUrl} alt={studentName} />
                    </div>
                </div>
                <div className="col-sm-8">
                    <h3 className="testimonial-title"> {studentName}, <h6> {place}  </h6> </h3>
                    <p className="mb-4 student-testimonial-para"> 
             
                        {studentTestimonial}
                    </p>
        
                    <Img 
                        fixed={query.rightQuote.edges[0].node.childImageSharp.fixed} 
                        className="right-quote-img"
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeTestimonialCard
