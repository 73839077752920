import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import axios from 'axios'
import validator from 'validator'

import { Modal, Button } from 'antd';
import "../css/rahul-styles.css"

const PopupForm = (props) => {

  const {time, handleTimeChange} = props 


  useEffect(() => {
    setTimeout(() => {
      showModal()
    }, time)
  }, [time])

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [qualification, setQualification] = useState('')
  const [destination, setDestination] = useState('')
  const [abroad, setAbroad] = useState('')

  const [questions, setQuestions] = useState('')
  const [policy, setPolicy] = useState(false) 
  const [formError, setFormError] = useState({})
  const errors = {}

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  

  const handleChange = (e) => {
    if(e.target.name === 'firstName') {
      setFirstName(e.target.value)
    } else if (e.target.name === 'lastName') {
      setLastName(e.target.value)
    } else if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else if (e.target.name === 'number') {
      setNumber(e.target.value)
    } else if (e.target.name === 'qualification') {
      setQualification(e.target.value)
    } else if (e.target.name === 'destination') {
      setDestination(e.target.value)
    } else if (e.target.name === 'questions') {
      setQuestions(e.target.value)
    } else if (e.target.name === 'abroad') {
      setAbroad(e.target.value)
    }
  }

  const handlePolicy = (e) => {
    const policyInput = e.target.checked 
    setPolicy(policyInput) 
  }

  const runValidations = () => {
    if(firstName.trim().length === 0) {
      errors.firstName = "Required"
    } 
  
    if (email.trim().length === 0) {
      errors.email = "Required"
    } else if (!validator.isEmail(email)) {
      errors.email = "Invalid E-Mail Format"
    }
    if (number.trim().length === 0) {
      errors.number = "Required"
    } else if (number.trim().length < 10) {
      errors.number = "Atleast 10 digits required"
    } 
    if (!qualification) {
      errors.qualification = "Required"
    }
    if (!destination) {
      errors.destination = "Required"
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault()

    runValidations()



    if(Object.keys(errors).length === 0) {
      setFormError({})
      const formData = {
        firstName: firstName,

        email: email,
        number: number,
        destination: destination,

        qualification: qualification,
  
        policy: policy,

      }


      setFirstName('')

      setEmail('')
      setNumber('')
      setDestination('')

      setQualification('')

      setPolicy(false)

     

      swal("Done!", "Our Admissions team will get in touch with you soon.", "success");

      handleCancel()

      axios.post('https://api.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r818cc16ada0b011c2981128aabe70284&secretKey=65a4a7aea451fbd17be5958bff3d23abe3bcb92f', [
        {
          "Attribute": "FirstName",
          "Value": firstName
        },
      
        {
          "Attribute": "EmailAddress",
          "Value": email
        },
        {
          "Attribute": "Phone",
          "Value": number
        },
        {
          "Attribute": "SearchBy",
          "Value": "Phone"
        },
        {
          "Attribute": "mx_Student_program_level",
          "Value": qualification
        },
        {
          "Attribute": "mx_Target_Destination",
          "Value": destination 
        },
       
        {
          "Attribute": "ProspectID",
          "Value": window ? window.MXCProspectId ? window.MXCProspectId : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx" : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx"
        },
    
        {
          "Attribute": "mx_Read_TWG_Privacy_Policy",
          "Value": policy
        },
        {
          "Attribute": "Source",
          "Value": "Website Enquiry"
        }
      ])
      .then(response => {
      })
      .catch(err => {
        alert(err.message)
    
      })

    } else {
      setFormError(errors)
    }
  }


  return (
    <>
  
      <Modal className="antd-modal-main" footer="null" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000} >
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-md-4 left-col p-0">
            </div>

            <div className="col-md-8 p-0">
              <div className="row top-row-img ">
                <div className="col-12 w-100 p-0">
                  <div className="header-text">
                    <h1 className="main-heading-text"> Get in touch with us </h1>
                  </div>
                </div>
              </div>

              <div className="container form-container">
                <div className="row">
                  <div className="col-12 mb-1">
                    <p className="sub-heading mb-0 text-color">
                      PERSONAL
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label form-label-popup">NAME*</label> {formError.firstName &&  <span className="text-danger error-message-text"> {formError.firstName} </span> }
                    <input 
                      name='firstName'
                      value={firstName}
                      onChange={handleChange}
                      type="text" 
                      className="form-control form-control-popup text-color" placeholder="Type Here" 
                    />
                  </div>
   
                  <div className="col-md-6">
                    <label className="form-label form-label-popup">E-MAIL*</label> {formError.email && <span className="text-danger error-message-text"> {formError.email} </span>}
                    <input
                      name='email'
                      value={email}
                      onChange={handleChange}
                      type="email" 
                      className="form-control form-control-popup" 
                      placeholder="Type Here" 
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label form-label-popup">PHONE NUMBER*</label> {formError.number && <span className="text-danger error-message-text"> {formError.number} </span>}
                    <input 
                      name='number'
                      value={number}
                      onChange={handleChange}
                      type="number" 
                      className="form-control form-control-popup" 
                      placeholder="+91" 
                    />
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12 mb-1">
                    <p className="sub-heading mb-0 text-color"> EDUCATION </p>
                  </div>
                  <div className="col-md-6">
                    
                    <label className="form-label form-label-popup"> QUALIFICATION YOU'RE SEEKING* </label> 
                    
                    <select 
                      className="form-select form-select-popup"
                      name="qualification"
                      value={qualification}
                      onChange={handleChange}
                    >
                      <option selected> Select One </option>
                      <option value="Undergraduate"> Undergraduate </option>
                      <option value="Postgraduate"> Postgraduate </option>
                    </select>
                    {formError.qualification && <span className="text-danger error-message-text"> {formError.qualification} </span>}
                  </div>
                  <div className="col-md-6">
                    
                    <label className="form-label form-label-popup"> PREFERRED EDUCATION DESTINATION* </label>

                    <select
                      name="destination"
                      value={destination}
                      onChange={handleChange}
                      className="form-select form-select-popup"
                    >
                      <option selected> Select One </option>
                      <option value="Australia"> Australia </option>
                      <option value="Dubai"> Dubai </option>
                      <option value="United Kingdom"> United Kingdom </option>
                      <option value="United States"> United States </option>
                      <option value="Other"> Other </option>
                    </select>
                    {formError.destination && <span className="text-danger error-message-text"> {formError.destination} </span>}
                  </div>
         
                  <div className="col-md-6 multi-select-container">

                  </div>

                </div>

                <div className="row">
            


                  <div className="col-12" style={{ marginLeft: "20px" }}>
                    
                    <label className="form-check-label" style={{ fontWeight: "500" }} >
                      <input 
                        checked={policy}
                        onChange={handlePolicy}
                        className="form-check-input" 
                        type="checkbox" 
                      />
                      I have read and understood <a href="/privacypolicy"> The WorldGrad Privacy Policy </a>
                    </label>
                  </div>         
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-4 button-body">
                    <a href="#">
                      <button 
                        type="button" 
                        className="btn btn-warning"
                        onClick={handleSubmit}
                        disabled={!policy}
                      >SUBMIT</button>
                    </a>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PopupForm
