import React, { useEffect } from 'react';
import EmployeeTestimonialCard from './employeeTestimonialCard';
import Swiper, { Pagination } from 'swiper';



const EmployeeTestimonialSlider = ({ EmployeeTestmonials }) => {

    useEffect(() => {
        // Initialize Swiper with HTML/JS
        new Swiper('.employee-swiper', {
            slidesPerView: 2,
            spaceBetween: 10,
            grid: {
                rows: 2, // Using grid to achieve the column effect
            },
            observer: true,
            observeParents: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    grid: {
                        rows: 1,
                    },
                    spaceBetween: 0,
                },
                480: {
                    slidesPerView: 2,
                    grid: {
                        rows: 2,
                    },
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    grid: {
                        rows: 2,
                    },
                    spaceBetween: 10,
                }
            }
        });
    }, []);

    return (
        <div className="mb-5 employee-swiper swiper-container">
            <div className="swiper-wrapper">
                {EmployeeTestmonials && EmployeeTestmonials.length && EmployeeTestmonials.map((testmonial) => (
                    <div className="swiper-slide" key={testmonial.id}>
                        <EmployeeTestimonialCard
                            studentName={testmonial.employee_name}
                            place={testmonial.employee_designation}
                            studentTestimonial={testmonial.employee_details}
                            imgUrl={testmonial.employee_profile_picture?.source_url || "https://assets.theworldgrad.com/gatsby-assets/xtra/Rahul-1-1-1.jpg"}
                        />
                    </div>
                ))}
            </div>
            <div className="swiper-pagination"></div>
        </div>
    );
}

export default EmployeeTestimonialSlider;
